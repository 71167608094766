import React from 'react';
import Container from "../Layout/Container";
import Hero from '../Sections/Blog/Hero';
import Footer from '../Sections/Home/Footer';
import BgColorContainer from '../Layout/BgColorContainer';
// import PageSeparator from '../ui/PageSeparator';

function Blog() {
    return (
        <Container>
            <BgColorContainer >
                <Hero />
                <Footer />
            </BgColorContainer>
        </Container>
    )
}

export default Blog;