import React from 'react';
import m1 from '../../../../assets/MoleculasSeo/m1.webp';
import m2 from '../../../../assets/MoleculasSeo/m2.webp';
import m3 from '../../../../assets/MoleculasSeo/m3.webp';
import m4 from '../../../../assets/MoleculasSeo/m4.webp';
import elipsis from '../../../../assets/MoleculasSeo/ElipseWhite.webp';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import DetalleServicio from './DetalleServicio';
import { cn } from '../../../../utils/cn';



function SubservicesList({ servicio }) {
    if (!servicio || !servicio.subservicios) {
        return <div>No hay subservicios disponibles.</div>;
    }

    const images = [
        { src: "https://awna.s3.sa-east-1.amazonaws.com/MoleculesServicesDetails/m1.webp", m2, alt: "molecule2", w: "70.292px", h: "58.34px", mdW: "175.73px", mdH: "145.85px" },
        { src: "https://awna.s3.sa-east-1.amazonaws.com/MoleculesServicesDetails/m2.webp", m2, alt: "molecule2", w: "70.292px", h: "58.34px", mdW: "175.73px", mdH: "145.85px" },
        { src: "https://awna.s3.sa-east-1.amazonaws.com/MoleculesServicesDetails/m3.webp", m2, alt: "molecule2", w: "70.292px", h: "58.34px", mdW: "175.73px", mdH: "145.85px" },
        { src: "https://awna.s3.sa-east-1.amazonaws.com/MoleculesServicesDetails/m4.webp", m2, alt: "molecule2", w: "70.292px", h: "58.34px", mdW: "175.73px", mdH: "145.85px" },
    ];

    return (
        <div className="w-full">
            {servicio?.subservicios.map((subservicio, index) => (
                <div className='flex items-center gap-5 justify-end'>
                    <div className={servicio?.color == 'white' ? 'border border-secondary rounded-full w-[200px] h-[200px] p-5 flex items-center ' : 'border border-primary rounded-full w-[200px] h-[200px] p-5 flex items-center '}>
                        <img src={'https://awna.s3.sa-east-1.amazonaws.com/Molecula2.webp'} alt="Molecule2" className=" object-contain animation-molecula-reverse duration-1000 ease-in" />
                    </div>
                    <p className={
                        cn(' font-bold text-xl w-1/2', {
                            'text-primary': servicio?.color !== 'white',
                            'text-secondary': servicio?.color == 'white'
                        })
                    }>
                        {subservicio}
                    </p>
                </div>

            ))}
        </div>
    );
}

export default SubservicesList