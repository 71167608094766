import React from 'react';
import ContactoPage from '../Sections/Contact/ContactoPage';
import Container from '../Layout/Container';
import Footer from '../Sections/Home/Footer';
import BgColorContainer from '../Layout/BgColorContainer';

function Contact() {
    return (
        <Container>


            <ContactoPage />

            <Footer />
        </Container>
    )
}

export default Contact