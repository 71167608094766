import React, { useContext } from 'react'
import Cta from '../../../ui/cta'
import BgColorContainer from '../../../Layout/BgColorContainer'
import CursorContext from '../../../providers/Cursor';
import { NavLink } from 'react-router-dom';

function ExtraService({ servicio, extraData }) {

    const isMobile = window.innerWidth < 768;
    const { blackSectionEnter, blackSectionExit } = useContext(CursorContext)

    return (
        <BgColorContainer color={servicio?.color == 'white' ? false : 'white'}>

            <div
                onMouseEnter={servicio?.color == 'white' ? blackSectionEnter : null}
                onMouseLeave={servicio?.color == 'white' ? blackSectionExit : null}
                className={servicio?.color == 'white' ? 'w-full h-full px-5 md:px-0 md:py-32 py-14 bg-black' : '  w-full h-full px-5 md:px-0 md:py-32 py-14 bg-primary'}>
                <div className='container flex flex-col md:flex-row '>
                    <div className='w-full md:w-1/2  flex flex-col gap-10'>
                        <h2 className={servicio?.color == 'white' ? 'text-primary text-left text-4xl md:text-5xl font-bold' : 'text-secondary text-left text-4xl md:text-5xl font-bold'}>
                            {extraData[0].titulo}
                        </h2>
                        <p className={servicio?.color == 'white' ? 'text-primary text-left text-xl md:text-2xl md:w-2/3' : 'text-secondary text-left text-xl md:text-2xl md:w-2/3 '}>
                            {extraData[0].descripcion}
                        </p>

                        <ul className='flex flex-col gap-10 md:w-2/3 w-full items-center justify-center'>
                            {
                                extraData[0].caracteristicas?.map((caracteristica, index) => (
                                    <li key={index} className={servicio?.color == 'white' ? 'text-primary text-base md:text-2xl list-disc text-justify' : 'text-secondary text-base md:text-2xl list-disc text-justify'}>
                                        {caracteristica}
                                    </li>
                                ))
                            }
                        </ul>
                        <NavLink to="/contact" >
                            <button className={'cursor-none flex items-center justify-center mt-auto  px-28 py-2 rounded-lg duration-300 transition-all minText bg-secondary hover:bg-zinc-500 text-primary w-full md:w-min'}>
                                Cotizar
                            </button>
                        </NavLink>
                        {/* <Cta text={'Cotizar'} link={'/contact'} width={!isMobile ? '20%' : '100%'} changeColor={true} /> */}

                    </div>
                    <img alt='molecula' src='https://awna.s3.sa-east-1.amazonaws.com/Moleculas/MoleculaHome.png' className='md:w-1/2 hidden md:block ' />
                </div >
            </div>
        </BgColorContainer>
    )
}

export default ExtraService