import React, { useContext } from 'react';
import CardServiceDetail from './CardServiceDetail';
import BgColorContainer from '../../../Layout/BgColorContainer';
import { cn } from '../../../../utils/cn';
import CursorContext from '../../../providers/Cursor';

function DetalleServicio({ servicio, detalle }) {

    const { blackSectionEnter, blackSectionExit } = useContext(CursorContext)

    return (
        <BgColorContainer color={servicio?.color == 'white' ? 'white' : false}>

            <div
                onMouseEnter={servicio?.color !== 'white' ? blackSectionEnter : null}
                onMouseLeave={servicio?.color !== 'white' ? blackSectionExit : null}
                className={
                    cn('w-full h-full px-5 md:px-0 md:py-32 py-10', {
                        'bg-primary': servicio?.color == 'white',
                        'bg-black': servicio?.color !== 'white'
                    })
                }>
                <div className=' container mx-auto w-full '>
                    {detalle && (
                        <div className='flex flex-col gap-10'>
                            <h2 className={
                                cn('text-4xl md:text-7xl font-bold', {
                                    'text-secondary': servicio?.color == 'white',
                                    'text-primary': servicio?.color !== 'white'
                                })
                                // servicio?.color == 'white' ? 'text-secondary text-left text-4xl md:text-7xl font-bold' : 'text-primary text-left text-4xl md:text-7xl font-bold'
                            }>
                                {detalle.titulo}
                            </h2>
                            <p className={
                                cn('text-left text-xl font-normal md:w-2/3', {
                                    'text-secondary': servicio?.color == 'white',
                                    'text-primary': servicio?.color !== 'white'
                                })
                            }>
                                {detalle.descripcion}
                            </p>
                        </div>
                    )}
                </div>
                <div className={
                    cn('inline-flex w-full border-dashed border-b-2 h-1 opacity-40 my-12', {
                        'border-secondary': servicio?.color == 'white',
                        'border-primary': servicio?.color !== 'white'
                    })
                }></div>
                <div className='max-w-[1000px] w-full mx-auto'>
                    <div className={detalle.contenido.length >= 3 ? 'grid grid-cols-1 md:grid-cols-3 gap-10 justify-items-center' : 'grid grid-cols-1 md:grid-cols-2 gap-10 justify-items-center'}>

                        {
                            detalle.contenido.map((item, index) => (
                                <CardServiceDetail key={index} servicio={servicio} item={item} />
                            ))
                        }
                    </div>
                    {
                        detalle?.extra &&
                        <div className='bg-primary text-secondary w-full px-10 py-5 mt-10 rounded-3xl'>
                            <h3 className='text-2xl font-bold'>
                                {detalle.extra.titulo} :
                            </h3>
                            <ul>
                                {detalle.extra.caracteristicas.map((caracteristica, idx) => (
                                    <li key={idx} className='text-base  -mb-0.5'>
                                        • {caracteristica}
                                    </li>
                                ))}
                            </ul>

                        </div>
                    }
                </div>
            </div>
        </BgColorContainer>
    )
}

export default DetalleServicio