import React from 'react'
import Slider from "react-slick";
import Entradas from '../../../Data/Entradas.json'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";


function CarrouselBlog({ slider }) {
    const isMobile = window.innerWidth < 768;

    // const [display, setDisplay] = useState(true);
    // const [width, setWidth] = useState(600);

    const settings = {
        infinite: true,
        speed: 300,
        slidesToShow: isMobile ? 1 : 2,
        slidesToScroll: 1,
        arrows: false,
        centerMode: isMobile ? false : true,
        // autoplay: true,
        // speed: 2000,
        // autoplaySpeed: 1000,
        cssEase: "linear",
    };





    {
        return (
            <>
                <Slider ref={slider} {...settings}>
                    {Entradas.map((item, i) => {

                        const concatenateValues = (arr) => arr.join(' ');
                        const readingTime = require('reading-time/lib/reading-time');
                        // Concatenamos los párrafos de la entrada seleccionada
                        const paragraphsText = concatenateValues(item.parrafos);

                        // Calcular el tiempo de lectura de la entrada seleccionada
                        const stats = readingTime(paragraphsText);

                        return (
                            <div key={i} className="flex">
                                <div className="bg-transparent rounded-lg shadow-lg md:pr-4">
                                    <NavLink to={`/blog/${i + 1}`}>
                                        <img
                                            className="w-[90%] h-[60%] 2xl:w-[619px] 2xl:h-[360px] object-cover"
                                            src={item.url}
                                            alt={item.title}
                                        />
                                    </NavLink>
                                    <div className="pt-4">
                                        <p className="text-lg font-bold text-white text-start">
                                            {item.title}
                                        </p>
                                    </div>
                                    <div className="pt-4">
                                        <p className="text-sm font-bold text-white text-start opacity-60">
                                            Tiempo de lectura: {stats.text.split(' ')[0]} {stats.text.split(' ')[1]} de lectura
                                        </p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </Slider>
            </>

        )
    }
}

export default CarrouselBlog;