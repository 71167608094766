import React, { useContext } from 'react';
import Carrousel from './Carrousel';
import { TfiArrowCircleRight } from "react-icons/tfi";
import { TfiArrowCircleLeft } from "react-icons/tfi";
import CursorContext from '../../providers/Cursor';


function CarouselContainer() {

    const { blackSectionEnter, selectExit } = useContext(CursorContext);

    const slider = React.useRef(null);

    return (
        <div  className='relative'>
            <div className='flex w-full gap-5 absolute top-[-130px] bg-inherit  md:right-0 justify-end'>
                <TfiArrowCircleLeft onMouseEnter={blackSectionEnter} onMouseLeave={selectExit} onClick={() => slider.current?.slickPrev()} className='w-12 h-12 text-white opacity-75 font-[10] hover:opacity-60' />
                <TfiArrowCircleRight onMouseEnter={blackSectionEnter} onMouseLeave={selectExit} onClick={() => slider.current?.slickNext()} className='w-12 h-12 text-white opacity-75 font-[10] hover:opacity-60' />
            </div>
            <Carrousel
                slider={slider} />
        </div>
    );
}
export default CarouselContainer