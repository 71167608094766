import React from "react";
import Video from "../../../assets/footer2.webp";
import Grain from "../../../assets/GrainServices.webp";
import Grain2 from "../../../assets/GrainServices2.webp";
import Graphics from "../../../assets/GraficoBubble.webp";
import PageSeparator from "../../ui/PageSeparator";
import BgColorContainer from "../../Layout/BgColorContainer";

function Hero() {
  return (
    <>
      <BgColorContainer color={'white'} >

        <div className="bg-primary flex flex-col w-full gap-10 ">
          <img src={"https://awna.s3.sa-east-1.amazonaws.com/GrainServices.webp"} className="absolute z-index-0  w-full bg-no-repeat opacity-60" />
          <div className="container w-full px-5 md:px-0">
            <div className="w-full">
              <h1 className="text-secondary opacity-50 text-2xl m-auto md:text-7xl pt-32 md:pt-48 ">
                Nos dedicamos con pasión a ser el aliado estratégico que nuestros
                clientes necesitan para crecer en la era digital.
              </h1>
            </div>
          </div>
          <div className="md:pt-24 ">

            <PageSeparator />
          </div>
          <div className="w-full">
            <img src={"https://awna.s3.sa-east-1.amazonaws.com/metodologi%CC%81a.svg"} className="absolute px-4 top-[62%] md:top-[30%] 2xl:top-[35%] md:px-8" />
            <div className="relative lg:py-20 container px-5 ">
              <p className="md:absolute md:max-w-[30%] 2xl:max-w-[25%] 2xl:text-xl md:text-lg text-2xl opacity-50  text-justify">
                Nos especializamos en comprender profundamente las necesidades y
                en interpretar con precisión y audacia los requerimientos
                específicos de cada negocio. Creemos en la innovación como motor
                de desarrollo y en el poder de lo digital para transformar y
                expandir las oportunidades de tu empresa.
              </p>
              <img src={"https://awna.s3.sa-east-1.amazonaws.com/NewBubbleServices.webp"} className="p-8 md:w-[80%] md:m-auto pt-20" />
            </div>
          </div>
        </div>
      </BgColorContainer>
    </>
  );
}

export default Hero;
