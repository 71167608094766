import React, { useContext } from "react";
import { SlArrowRight } from "react-icons/sl";
import CursorContext from "../../providers/Cursor";
import FooterItems from "../../../Data/FooterItems.json";
import FooterItem from "../../ui/FooterItem";
import BgColorContainer from "../../Layout/BgColorContainer";
import { Button } from "antd";
import { NavLink } from "react-router-dom";
// import footerVideoWebm from "../../../assets/videos/awna_cell_anim_5.mov";
import SocialLinks from "../Footer/SocialLinks";
// import footerVideoMp4 from '../../../assets/videos/awna_cell_anim_5.mp4'; // Asegúrate de tener este archivo

function Footer() {
  const { blackSectionEnter, blackSectionExit } = useContext(CursorContext);

  return (
    <BgColorContainer >
      <div
        onMouseEnter={blackSectionEnter}
        onMouseLeave={blackSectionExit}
        className="bg-black md:h-[150vh] 2xl:h-screen h-min w-full relative overflow-hidden"
      >

        {/* Contenido Existente */}
        <div className="container flex flex-col gap-16 py-16 p-5 lg:px-10 2xl:px-0 relative z-10">
          <div className="flex justify-start items-center w-full mb-8">
            <img
              src={"https://awna.s3.sa-east-1.amazonaws.com/AwnaWhite.webp"}
              className="
                w-[100px] 
                h-auto 
                md:w-[126.76px] 
                lg:w-[150px] 
                object-contain 
                transition-all 
                duration-300 
                ease-in-out
                transform 
                hover:scale-105 
                hover:rotate-1 
                focus:outline-none 
                focus:ring-2 
                focus:ring-primary 
                focus:ring-opacity-50"
              alt="Awna Logo"
            />
          </div>

          <div className="flex gap-10 relative">
            <p className="text-[20px] text-primary opacity-50 text-justify md:w-1/4">
              En nuestra empresa, te apoyamos a potenciar el crecimiento de tu
              marca. Juntos, llevamos tu negocio al siguiente nivel.
            </p>
            <img
              src={"https://awna.s3.sa-east-1.amazonaws.com/Asset1.webp"}
              className="static w-[15px] h-[108px] object-cover z-0 hidden md:block"
              alt="Decorative Asset"
            />
          </div>

          <div className="inline-flex w-full relative">
            <ul className="flex flex-col gap-5 md:flex-row md:gap-0 justify-between w-full">
              {FooterItems.map((item) => (
                <li key={item.id} className="group relative">
                  <FooterItem
                    nombre={item.nombre}
                    link={item.link}
                    className="transition-transform duration-300 transform group-hover:scale-105"
                  />
                </li>
              ))}
            </ul>
          </div>

          <div className="flex flex-col md:flex-row justify-end gap-4 md:gap-5 items-start w-full overflow-hidden relative">
            <NavLink to={"/contact"} className="group relative inline-block">
              <div className="flex items-center justify-center md:space-x-2 h-full">

                <Button
                  className="
                        bg-white 
                        text-secondary 
                        py-5
                        px-4 
                        rounded-none 
                        relative 
                        overflow-hidden 
                        group/contact
                      "
                >
                  <span
                    className="
                        relative 
                        z-10 
                        transition-transform 
                        duration-300 
                        group-hover/contact:translate-y-[-100%]"
                  >
                    contacto@awna.cl
                  </span>
                  <span
                    className="
                        absolute
                        ml-4 
                        left-0 
                        top-full 
                        z-10 
                        text-secondary 
                        transition-transform 
                        duration-300 
                        group-hover/contact:translate-y-[-120%]"
                  >
                    Contactar
                  </span>
                </Button>
              </div>
            </NavLink>

            <SocialLinks />
          </div>
        </div>
        <div className="relative bg-black h-[30%] hidden lg:block">
          <video
            autoPlay
            loop
            muted
            playsInline
            className="relative top-0 left-0 w-full h-full object-cover z-0 opacity-30"
          >
            <source src={"https://awna.s3.sa-east-1.amazonaws.com/videos/awna_cell_anim_5.mov"} type="video/webm" />
            Tu navegador no soporta videos HTML5
          </video>
          <img src={"https://awna.s3.sa-east-1.amazonaws.com/LogoWhite3.webp"} alt=""
            className="lg:bottom-32 2xl:bottom-24 z-10 absolute h-[30%] lg:left-[46%] hidden lg:block" />
        </div>
      </div>

    </BgColorContainer>
  );
}

export default Footer;
