import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Pages/Home";
import AboutUs from "./components/Pages/AboutUs";
import Services from "./components/Pages/Services"
import Contact from "./components/Pages/Contact";
import Blog from "./components/Pages/Blog";
import EntradaDeBlog from "./components/Pages/EntradaDeBlog";
import DetalleServicio from './components/Sections/Services/ServicesDetail/DetalleServicio';
import EntradaData from './Data/Entradas.json'
import ServiciosData from './Data/Servicios.json';
import ServicesDetail from "./components/Pages/ServicesDetail";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services/:id" element={<ServicesDetail ServiciosData={ServiciosData} />} /> {/* Ruta dinámica */}
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<EntradaDeBlog EntradaData={EntradaData} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
