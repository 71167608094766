import { useInView } from 'framer-motion'
import React, { useContext, useEffect, useRef } from 'react'
import ColorContext from '../providers/ColorContainer'

function BgColorContainer({ children, disable, color }) {
    const ref = useRef(null);
    const { setBgColor, bgColor } = useContext(ColorContext);

    // Detecta si el contenedor está en la vista
    const inView = useInView(ref, {
        margin: "0px 1000px -1000px 0px"
    });

    // El efecto que actualiza el color de fondo


    useEffect(() => {

        if (inView && color == 'white') {
            setBgColor("white");
        }

        if (inView && !color) {
            setBgColor("black"); // Cambia el color de fondo a negro cuando el contenedor está en vista
        }

    }, [inView, setBgColor, color]); // Dependencias: se ejecuta cuando inView cambia




    return (
        <div ref={ref}>
            {children}
        </div>
    )
}

export default BgColorContainer