import React, { useEffect, useState } from 'react';
import Container from "../Layout/Container";
import Hero from '../Sections/Services/ServicesDetail/Hero';
import DetalleServicios from '../Sections/Services/ServicesDetail/DetalleServicio';
import Footer from '../Sections/Home/Footer';
import BgColorContainer from '../Layout/BgColorContainer';
import { useParams } from 'react-router-dom';
import PreguntasFrecuentes from '../Sections/Services/ServicesDetail/PreguntasFrecuentes';
import ExtraService from '../Sections/Services/ServicesDetail/ExtraService';
import AwnaBeneficios from '../Sections/Services/ServicesDetail/AwnaBeneficios';
import PageSeparator from '../ui/PageSeparator';
import Message from '../Sections/Services/ServicesDetail/Message';
import App from '../Sections/Services/ServicesDetail/App';
import Explication from '../Sections/Services/ServicesDetail/Explication';

// import PageSeparator from '../ui/PageSeparator';

function ServicesDetail({ ServiciosData }) {

    const { id } = useParams();
    const [servicio, setServicio] = useState(null);

    useEffect(() => {

        if (!ServiciosData) {
            console.error('No se encontraron datos de servicio.');
            return;
        }
        // Buscar el servicio correspondiente al id
        const servicioFound = ServiciosData.find((s) => s.id === parseInt(id));
        if (servicioFound) {
            setServicio(servicioFound); // Si lo encuentra, lo guarda en el estado
        } else {
            console.error('Servicio no encontrado');
        }
    }, [id, ServiciosData]);


    return (
        <Container>
            <Hero servicio={servicio} />
            <BgColorContainer >
                <PageSeparator />
            </BgColorContainer>

            {
                servicio?.explicacion &&
                <Explication servicio={servicio} detalle={servicio?.explicacion} />
            }


            {
                servicio?.detalle && servicio?.detalle.map((detalle, index) => (
                    <DetalleServicios key={index} servicio={servicio} detalle={detalle} />
                ))
            }


            {
                servicio?.extra &&
                <ExtraService servicio={servicio} extraData={servicio?.extra} />
            }
            {
                servicio?.app &&
                <App servicio={servicio} app={servicio?.app} />
            }

            {
                servicio?.beneficios &&
                <AwnaBeneficios servicio={servicio} />
            }


            {
                servicio?.Preguntas &&
                <PreguntasFrecuentes servicio={servicio} />
            }

            {
                servicio?.mensaje &&
                <Message servicio={servicio} mensaje={servicio.mensaje} />
            }

            <Footer />

        </Container>
    )
}

export default ServicesDetail;