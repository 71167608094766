import React, { useContext } from "react";
import { SlArrowRight } from "react-icons/sl";
import SectionTitle from "../../ui/SectionTitle";
import CursorContext from "../../providers/Cursor";
import ServiciosData from "../../../Data/Servicios.json";
import ServiceItem from "../../ui/ServiceItem";
import BgColorContainer from "../../Layout/BgColorContainer";
import { useState } from 'react';

function Servicios() {

  const { blackSectionEnter, blackSectionExit } = useContext(CursorContext);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleHoverImage = (img) => {
    setSelectedImage(img);
  };

  return (
    <BgColorContainer >

      <div
        onMouseEnter={blackSectionEnter}
        onMouseLeave={blackSectionExit}
        className="bg-black h-full 2xl:h-min relative overflow-hidden"
      >
        <div className="lg:p-7 2xl:p-0 h-full">
          <img
            src={"https://awna.s3.sa-east-1.amazonaws.com/whitegrain.webp"}
            className="absolute inset-0 w-full h-full object-cover z-1 opacity-40"
            alt="Fondo"
          />
          <div className="w-full py-10 px-5 container">
            <div className="flex flex-col md:flex-row md:items-center items-start gap-10 rounded-lg mt-3 -mb-3">
              <SectionTitle text="Servicios" />
              <SlArrowRight className="text-primary w-7 h-13 hidden md:block " />
              <p className="text-xl text-primary opacity-50 md:mt-10 text-justify ">
                Soluciones innovadoras en transformación digital. optimizando
                procesos <br className="hidden md:block" />y mejorando la
                eficiencia a través de la tecnología.
              </p>
            </div>
          </div>
          <div className="inline-flex w-full border-dashed border-b-2 border-primary h-1 opacity-40"></div>

          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-10 md:pt-10 container px-5 h-full lg:pb-32 2xl:pb-52">
            <ul className="list-none flex flex-col items-center py-10 2xl:py-5">
              {ServiciosData.map((servicio) => {
                return (
                  <ServiceItem
                    key={servicio.id}
                    nombre={servicio.nombre}
                    index={servicio.id}
                    img={servicio.imagen}
                    imgClasses="absolute w-[200px] 2xl:w-[300px] right-[20%] top-[80%] object-contain"
                    onHoverImage={handleHoverImage}
                  />
                );
              })}
            </ul>
            <img
              src={
                "https://awna.s3.sa-east-1.amazonaws.com/Moleculas/awna_+molecula_3.png"
              }
              className="w-full  2xl:-mt-36"
            />
          </div>
        </div>
      </div>

    </BgColorContainer>
  );
}

export default Servicios;
