import React from 'react';
import Container from "../Layout/Container";
import Hero from '../Sections/Services/Hero'
import ListaServicios from '../Sections/Services/ListaServicios';
import Footer from '../Sections/Home/Footer';
import Servicios from '../Sections/Home/Servicios';
// import PageSeparator from '../ui/PageSeparator';

function Services() {
    return (
        <Container>
            <Hero />
            <Servicios />
            {/* <ListaServicios /> */}
            <Footer />
        </Container>
    )
}

export default Services;