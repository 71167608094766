import React from 'react';
import CarouselContainer from './CarrouselContainer';


function Hero() {
    return (
        <div className='flex bg-black w-full h-min px-5 md:px-0 z-10'>
            <div className='container py-32'>
                <div className='flex flex-col  w-full'>
                    <h1 className='text-6xl text-primary'>Explora nuestras ideas y tendencias digitales.</h1>
                    <div className='w-full md:w-1/2 mt-12 flex'>
                        <p className='text-xl text-primary'>En nuestro blog, compartimos las últimas tendencias, insights y guías prácticas
                            sobre transformación digital, marketing y tecnología. Mantente al día con todo lo que necesitas saber
                            para impulsar tu negocio en la era digital.</p>
                        <img
                            src={"https://awna.s3.sa-east-1.amazonaws.com/Asset2.webp"}
                            className="static w-[15px] h-[45px] object-cover z-0 ml-4 mt-3 hidden justify-end md:block"
                            alt="Decorative Asset"
                        />
                    </div>

                    <div className="container mt-10 ">
                        <CarouselContainer />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero