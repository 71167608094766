import React, { useContext, useState } from "react";
import { SlArrowDown } from "react-icons/sl";
import CursorContext from "../providers/Cursor";
import { Popover, Select } from "antd";

function FormArea({ onAreaChange }) {
  const { selectEnter, selectExit } = useContext(CursorContext);
  const [value, setValue] = useState(null);

  const items = [
    {
      key: "1",
      value: "Transformación Digital",
    },
    {
      key: "2",
      value: "Marketing Digital",
    },
    {
      key: "3",
      value: "Marca y Diseño",
    },
    {
      key: "4",
      value: "Desarrollos Web y Reportería",
    },
    {
      key: "5",
      value: "App AwnaGo",
    },
    {
      key: "6",
      value: "Customer Experience",
    },
    {
      key: "7",
      value: "Otro",
    }
  ];

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setValue(selectedValue);
    onAreaChange(selectedValue);
  };

  const content = () => {
    return (
      <div className="flex flex-col">
        {items.map((item) => (
          <label
            key={item.key}
            htmlFor={item.key}
            className="flex items-center gap-2 hover:opacity-80 cursor-pointer"
          >
            <input
              type="radio"
              name="area"
              id={item.key} // Agrega un id al input
              value={item.value}
              checked={value === item.value}
              onChange={handleChange}
            />
            <span>{item.value}</span> {/* Cambia <label> por <span> */}
          </label>
        ))}
      </div>
    );
  };

  return (
    <div
      onMouseEnter={selectEnter}
      onMouseLeave={selectExit}
      className="md:w-1/2 w-full"
    >
      <Popover
        placement="bottom"
        content={content}
        className="bg-gray border-none px-10 py-7 rounded-sm flex items-center justify-center gap-5 cursor-none"
      >
        <p className="text-xl opacity-50">Área a cotizar</p>
        <SlArrowDown color="#333333" size={15} />
      </Popover>
    </div>
  );
}

export default FormArea;
