import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Button } from 'antd';
import { SlHeart } from "react-icons/sl";
import { BsBoxArrowUp } from "react-icons/bs";
import { FaArrowLeft } from 'react-icons/fa';
import CursorContext from '../../providers/Cursor';

function EntradaBlog({ EntradaData }) {

    const { selectEnter, selectExit } = useContext(CursorContext);
    const { id } = useParams();  // Obtenemos el id de la URL
    const [entrada, setEntrada] = useState(null);

    useEffect(() => {
        if (!EntradaData) {
            console.error('No se encontraron datos de Entrada.');
            return;
        }

        // Buscar la entrada correspondiente al id
        const EntradaFound = EntradaData.find((entrada) => entrada.id === parseInt(id));

        if (EntradaFound) {
            setEntrada(EntradaFound); // Si se encuentra, actualizamos el estado
        } else {
            console.error('Entrada no encontrada');
        }
    }, [id, EntradaData]); // Dependemos de EntradaData y del id para hacer la búsqueda

    if (!entrada) {
        return <div>Entrada no encontrada</div>; // Si no se encuentra la entrada, mostramos un mensaje
    }

    // Función para concatenar los párrafos de la entrada específica
    const concatenateValues = (arr) => arr.join(' ');


    const readingTime = require('reading-time/lib/reading-time');
    // Concatenamos los párrafos de la entrada seleccionada
    const paragraphsText = concatenateValues(entrada.parrafos);

    // Calcular el tiempo de lectura de la entrada seleccionada
    const stats = readingTime(paragraphsText);

    return (
        <div className="flex w-full h-full bg-primary px-5 py-24">

            <div className='w-full max-w-[1200px] mx-auto'>
                <div className="flex flex-col md:p-24 w-full">
                    <div className="flex items-center gap-5">
                        <a
                            onMouseEnter={selectEnter}
                            onMouseLeave={selectExit}
                            href="/blog" className=' cursor-none '>
                            <FaArrowLeft
                                onMouseEnter={selectEnter}
                                onMouseLeave={selectExit}
                                className='inline-block' size={40} />
                        </a>
                        <p className="text-base text-secondary opacity-60 ">
                            {stats.text.split(' ')[0]} {stats.text.split(' ')[1] + " de lectura"} {/* Mostrar el tiempo de lectura en formato texto */}
                        </p>
                        {/* <div className="flex space-x-2 pb-1 gap-3">
                        <SlHeart className='text-secondary opacity-60' size={25} />
                        <BsBoxArrowUp className='text-secondary opacity-60' size={25} />
                        </div> */}
                    </div>

                    <div className="inline-flex w-full border-dashed border-b-2 border-black h-1 opacity-40 overflow-visible pt-2"></div>
                    <div className=" gap-12 flex-col">

                        <div className='py-10'>
                            <h1 className="text-2xl md:text-6xl text-secondary opacity-50 text-justify">{entrada.title}</h1>
                        </div>
                        <div className="flex flex-row gap-3 pb-12">
                            <div className="bg-secondary rounded-3xl text-primary text-xl px-6 py-2 cursor-none">Digital</div>
                            <div className="bg-secondary rounded-3xl text-primary text-xl px-6 py-2">Clientes</div>
                            {/* <Button className="bg-secondary rounded-3xl text-primary text-xl px-6 py-2">AI</Button> */}
                        </div>
                        <div className='h-[226.4px] w-[99%] lg:h-[500px] lg:w-full 2xl:h-[566px] 2xl:w-[1006px] relative  overflow-hidden'>
                            <img
                                src={entrada.url}
                                alt={entrada.title}
                                className="w-full h-fit object-fill"
                            />
                        </div>
                        <div className="w-full pt-7 2xl:pt-6 text-justify">
                            {/* Mostrar los párrafos de la entrada seleccionada */}
                            {entrada.parrafos.map((parrafo, index) => (
                                <p key={index} className="text-xl md:w-full 2xl:w-full  py-4 text-secondary opacity-80 tracking-wide md:tracking-normal">
                                    {parrafo}
                                </p>
                            ))}
                        </div>
                    </div>
                    {/* <div className="flex w-[95%] md:w-[92%] 2xl:w-4/5 2xl:pl-80 py-8 justify-end gap-3">
                        <SlHeart className='text-secondary opacity-60' size={25} />
                        <BsBoxArrowUp className='text-secondary opacity-60' size={25} />
                    </div> */}
                    <div className="inline-flex w-full border-dashed border-b-2 border-black h-1 opacity-40 overflow-visible"></div>
                </div>
            </div>
        </div>
    );
}


export default EntradaBlog;