import React from "react";
import { delay, motion } from "framer-motion";
import { useAnimate } from "framer-motion";
import { NavLink, Link } from 'react-router-dom';
import { useContext } from "react";
import CursorContext from "../providers/Cursor";

function ServiceItem({ nombre, img, index, imgClasses, onHoverImage }) {
  const [scope, animate] = useAnimate();
  // const { isListaServicios } = useContext(CursorContext);
  const isMobile = window.innerWidth < 768;      //|| isListaServicios




  const handleHover = () => {
    if (!img) return;
    animate("img", { opacity: 1 }, { duration: 0.5, ease: "easeInOut" });
    onHoverImage(img);
  };

  const handleHoverEnd = () => {
    if (!img) return;
    animate("img", {
      opacity: 0,
    });
  };

  const attributesLi = {
    whileHover: { translateX: isMobile ? 0 : 50, opacity: 1, zIndex: 50 },
    initial: { translateX: 0, opacity: isMobile ? 1 : 0.4, zIndex: 0.2 },
  };

  // const shouldRenderImage = !(isMobile || isListaServicios);

  return (
    <motion.li
      ref={scope}
      {...attributesLi}
      onHoverStart={handleHover}
      onHoverEnd={handleHoverEnd}
      className="text-2xl md:text-3xl lg:text-5xl py-6 md:py-8 w-full whitespace-pre-wrap text-primary opacity-100 font-bold  md:hover:opacity-100 md:hover:blur-none  md:blur-[3px] relative uppercase"
    >
      <NavLink to={`/services/${index}`}><p className=" break-words" >{nombre}</p></NavLink>
      {img && (
        <motion.img
          initial={{ opacity: 0 }}
          src={img}
          alt="Hover"
          className={`${imgClasses} hidden md:block absolute `}
        />
      )}
    </motion.li>
  );
}

export default ServiceItem;
