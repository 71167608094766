import React, { useContext, useState } from 'react';
import { motion, useAnimate } from 'framer-motion';
import CursorContext from '../providers/Cursor';



function NosotrosCard({ person }) {
    const [scope, animate] = useAnimate()
    const { selectEnter, selectExit } = useContext(CursorContext)
    


    const isMobile = window.innerWidth < 768;


    const handleHover = () => {
        animate('div', { opacity: 1, gap: '15px' }, { duration: 0.3, ease: 'easeInOut' })
    }

    return (
        <motion.div 
        key={person.id} 
        ref={scope} 
        onHoverStart={handleHover} 
        onMouseEnter={selectEnter} 
        onMouseLeave={selectExit}
        className='flex flex-col items-center gap-5'>
            <img src={person.imagen} className='rounded-full w-[239px] h-[239px]' />
            <motion.div 
            initial={{ opacity:isMobile ? 1 : 0, gap: 0 }} 
            className='flex flex-col md:flex-row  justify-between items-center'>
                <p className='text-[20px] text-justify '>{person.nombre}</p>
                <p className='text-[16px] text-justify opacity-60'>{person.cargo}</p>
            </motion.div>
        </motion.div>
    )

}

export default NosotrosCard