import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import SubservicesList from './SubservicesList';
import ColorContext from '../../../providers/ColorContainer';
import BgColorContainer from '../../../Layout/BgColorContainer';
import { cn } from '../../../../utils/cn';
import { Button } from 'antd';
import { FaArrowLeft } from 'react-icons/fa';
import CursorContext from '../../../providers/Cursor';

function Hero({ servicio }) {

    const { blackSectionEnter, blackSectionExit } = useContext(ColorContext)
    const { selectEnter, selectExit } = useContext(CursorContext)

    return (
        <BgColorContainer color={servicio?.color == 'white' ? 'white' : false} >
            <div
                onMouseEnter={servicio?.color !== 'white' ? blackSectionEnter : null}
                onMouseLeave={servicio?.color !== 'white' ? blackSectionExit : null}
                className={cn('w-full px-5', {
                    'bg-primary': servicio?.color == 'white',
                    'bg-black': servicio?.color !== 'white'
                })}            >
                <div className='container w-full flex flex-col gap-10 py-32 justify-between items-center'>
                    <div className='flex flex-col gap-10 w-full'>
                        <a
                            onMouseEnter={selectEnter}
                            onMouseLeave={selectExit}
                            href="/services" className=' cursor-none'>
                            <FaArrowLeft
                                onMouseEnter={selectEnter}
                                onMouseLeave={selectExit}
                                className={cn({
                                    'text-primary': servicio?.color !== 'white',
                                    'text-secondary': servicio?.color == 'white'
                                })} size={40} />
                        </a>

                        <h1 className={cn(' text-4xl md:text-7xl font-bold', {
                            'text-secondary': servicio?.color == 'white',
                            'text-primary': servicio?.color !== 'white'
                        })}>
                            {servicio?.titulo}
                        </h1>

                        <p className={cn(' text-2xl', {
                            'text-secondary': servicio?.color == 'white',
                            'text-primary': servicio?.color !== 'white'
                        })}>{servicio?.subtitulo}</p>
                    </div>
                    <div className=' flex flex-col md:flex-row justify-between items-center'>
                        <div className='md:w-1/2'>
                            <p className={
                                cn('text-xl opacity-50 text-justify', {
                                    'text-secondary': servicio?.color == 'white',
                                    'text-primary': servicio?.color !== 'white'
                                })
                            }>
                                {servicio?.descripcion}
                            </p>
                        </div>
                        {
                            servicio?.subservicios &&
                            <div className="">
                                <SubservicesList servicio={servicio} />
                            </div>
                        }
                        {
                            servicio?.carrousel &&
                            <div className='w-full md:w-2/5 flex items-center justify-center'>
                                <img src={servicio?.carrousel[0]} className='rounded-md' />
                            </div>
                        }

                    </div>
                </div>
            </div>
        </BgColorContainer>
    )
}

export default Hero