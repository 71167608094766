import React from 'react'
import CarrouselContainer from './CarrouselContainer';
import Videoimg from '../../../assets/footer2.webp';
import PageSeparator from '../../ui/PageSeparator';

function Message() {
    return (

        <>

            <div className='flex flex-col p-5 md:p-0'>
                <div className='w-full relative  '>
                    <div className='container w-full py-10'>
                        <img src={"https://awna.s3.sa-east-1.amazonaws.com/promesaBg.webp"} className='absolute inset-0 w-full md:px-14  object-cover z-0 opacity-80' alt="Fondo" />
                        <p className='text-base md:text-xl text-primary text-justify md:w-2/5 mb-40 md:mb-20 opacity-50'>
                            Nuestra misión es liderar la transformación digital de las empresas,
                            potenciando su crecimiento y optimización a través de soluciones
                            tecnológicas innovadoras y personalizadas. Aspiramos a ser
                            reconocidos como referentes en el sector, guiando a nuestros
                            clientes en su camino hacia el éxito en un entorno competitivo y en
                            constante evolución.
                        </p>
                    </div>
                </div>
                <div className='flex flex-col gap-10'>
                    <div className='inline-flex w-full border-dashed border-b-2 border-primary h-1 opacity-40 '></div>
                    <div className='container'>
                        <CarrouselContainer />
                    </div>
                    <div className='inline-flex w-full border-dashed border-b-2 border-primary h-1 opacity-40 '></div>
                </div>


            </div>
            {/* <div><img src={"https://awna.s3.sa-east-1.amazonaws.com/footer2.webp"} className='inset-0 w-[1920px] h-[392px] object-cover z-1 mt-24 hidden  md:block' alt="Fondo" /></div> */}

        </ >

    )
}

export default Message