import React from 'react';
import Container from "../Layout/Container";
import EntradaBlog from '../Sections/Blog/EntradaBlog';
import DetalleServicios from '../Sections/Services/ServicesDetail/DetalleServicio';
import Footer from '../Sections/Home/Footer';
import BgColorContainer from '../Layout/BgColorContainer';

// import PageSeparator from '../ui/PageSeparator';

function EntradaDeBlog({ EntradaData }) {
    return (
        <Container className="bg-primary">
            <BgColorContainer color={'white'}>
                <EntradaBlog EntradaData={EntradaData} />
                <Footer />
            </BgColorContainer>
        </Container>
    )
}

export default EntradaDeBlog;