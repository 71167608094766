import React, { useContext } from 'react'
import BgColorContainer from '../../../Layout/BgColorContainer'
import { cn } from '../../../../utils/cn'
import { Button } from 'antd'
import Cta from '../../../ui/cta'
import CursorContext from '../../../providers/Cursor'
import { NavLink } from 'react-router-dom';

function Message({ servicio, mensaje }) {

    const { blackSectionEnter, blackSectionExit } = useContext(CursorContext)


    return (
        <BgColorContainer color={servicio?.color == 'white' ? false : 'white'}>
            <div
                onMouseEnter={servicio?.color == 'white' ? blackSectionEnter : null}
                onMouseLeave={servicio?.color == 'white' ? blackSectionExit : null}
                className={cn('w-full h-full px-5 md:px-0 md:py-32 py-14', {
                    'bg-black text-primary': servicio?.color == 'white',
                    'bg-primary text-secondary': servicio?.color !== 'white'
                })}>
                <div className='container flex flex-col gap-10'>
                    <h2 className='text-justify text-2xl md:text-5xl font-bold uppercase md:w-1/2'>
                        {
                            mensaje.titulo
                        }
                    </h2>
                    <p className='text-3xl md:w-1/2 text-justify'>
                        {
                            mensaje.descripcion
                        }
                    </p>

                    <NavLink to="/contact">
                        <button className=' w-full md:w-1/4 flex items-center justify-center mt-auto  px-36 py-2 rounded-lg duration-300 transition-all minText bg-secondary hover:bg-zinc-500 text-primary cursor-none'>
                            Cotizar
                        </button>
                    </NavLink>

                    {/* <Cta text={'Cotizar'} changeColor width={'25%'} rounded >

                    </Cta> */}
                </div>
            </div>
        </BgColorContainer >
    )
}

export default Message