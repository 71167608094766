import React, { useContext } from 'react'
import BgColorContainer from '../../../Layout/BgColorContainer'
import { cn } from '../../../../utils/cn'
import CursorContext from '../../../providers/Cursor'

function AwnaBeneficios({ servicio }) {

    const { blackSectionEnter, blackSectionExit } = useContext(CursorContext)

    return (
        <BgColorContainer color={servicio?.color == 'white' ? 'white' : false}>

            <div
                onMouseEnter={servicio?.color !== 'white' ? blackSectionEnter : null}
                onMouseLeave={servicio?.color !== 'white' ? blackSectionExit : null}
                className={
                    cn('w-full h-full px-5 md:px-0 md:pt-20 py-14', {
                        '!bg-primary text-secondary': servicio?.color == 'white',
                        '!bg-black text-primary': servicio?.color !== 'white'
                    })
                }>
                <div className='w-full container'>
                    <h2 className={'text-center text-4xl md:text-7xl font-bold'}>
                        Beneficios de elegir Awna
                    </h2>

                    <div className='flex flex-col-reverse md:flex-row '>
                        <img src={'https://awna.s3.sa-east-1.amazonaws.com/Moleculas/MoleculaHome.png'} className='w-[800px] object-contain' />
                        <ul className='flex flex-col md:items-end gap-5 mt-10 md:mt-20'>
                            <div className='flex flex-col gap-10 md:gap-20'>
                                {
                                    servicio?.beneficios?.map((beneficio, index) => (
                                        <li key={index} className={'text-base md:text-right md:text-2xl '}>
                                            {beneficio}
                                        </li>
                                    ))
                                }
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </BgColorContainer >
    )
}

export default AwnaBeneficios