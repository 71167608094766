import React, { useContext } from 'react'
import BgColorContainer from '../../../Layout/BgColorContainer'
import CursorContext from '../../../providers/Cursor'
import { cn } from '../../../../utils/cn'

function Explication({ servicio, detalle }) {


    const { blackSectionEnter, blackSectionExit } = useContext(CursorContext)

    return (
        <BgColorContainer color={servicio?.color == 'white' ? 'white' : false}>

            <div
                onMouseEnter={servicio?.color !== 'white' ? blackSectionEnter : null}
                onMouseLeave={servicio?.color !== 'white' ? blackSectionExit : null}
                className={
                    cn('w-full h-full px-5 md:px-0 md:py-32 py-10', {
                        'bg-primary': servicio?.color == 'white',
                        'bg-black': servicio?.color !== 'white'
                    })
                }>
                <div className=' container mx-auto w-full  flex '>
                    <div className='flex flex-col gap-10'>
                        <h2 className={
                            cn('text-4xl md:text-7xl font-bold', {
                                'text-secondary': servicio?.color == 'white',
                                'text-primary': servicio?.color !== 'white'
                            })
                        }>
                            {detalle?.titulo}
                        </h2>
                        <p className={
                            cn('text-justify text-xl font-normal md:w-2/4', {
                                'text-secondary': servicio?.color == 'white',
                                'text-primary': servicio?.color !== 'white'
                            })
                        }>
                            {detalle?.descripcion}
                        </p>
                    </div>
                </div>
            </div>
        </BgColorContainer >
    )
}

export default Explication