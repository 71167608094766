import React from 'react'
import BgColorContainer from '../../Layout/BgColorContainer'

function Hero() {
    return (
        <BgColorContainer color={'white'}>

            <div className='w-full bg-primary relative'>
                <img src={'https://awna.s3.sa-east-1.amazonaws.com/Grano.png'} className='absolute z-index-10 h-[100vh] w-full bg-no-repeat' />
                <div className='container h-screen flex items-center justify-center relative '>
                    <img src={'https://awna.s3.sa-east-1.amazonaws.com/Logo.webp'} className='w-[300px] h-[133px] z-index-10 ' />
                    {/* <Model /> */}
                </div>
                <img src={'https://awna.s3.sa-east-1.amazonaws.com/Moleculas/MoleculaHome.png'} className='absolute 2xl:-bottom-[850px] z-index-0 2xl:w-auto md:w-2/3 md:-bottom-[500px] hidden md:block' />
            </div>
        </BgColorContainer>
    )
}

export default Hero