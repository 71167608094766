import React, { useContext } from 'react'
import BgColorContainer from '../../../Layout/BgColorContainer'
import { cn } from '../../../../utils/cn'
import CursorContext from '../../../providers/Cursor'
import { Collapse } from 'antd'

function PreguntasFrecuentes({ servicio }) {

    const { selectEnter, selectExit, blackSectionEnter, blackSectionExit } = useContext(CursorContext)

    return (
        <BgColorContainer BgColorContainer color={servicio?.color == 'white' ? 'white' : false}>

            <div
                onMouseEnter={servicio?.color !== 'white' ? blackSectionEnter : null}
                onMouseLeave={servicio?.color !== 'white' ? blackSectionExit : null}
                className={
                    cn('w-full h-full md:px-0 px-5 py-14', {
                        '!bg-primary text-secondary': servicio?.color == 'white',
                        '!bg-black text-primary': servicio?.color !== 'white'
                    })
                }>
                <div className='container w-full flex flex-col gap-16 items-center justify-center'>
                    <h2 className='md:text-5xl text-4xl  font-bold'>
                        Preguntas Frecuentes
                    </h2>
                    {
                        servicio?.preguntasSeparadas && servicio?.preguntasSeparadas.map((pregunta, index) => (
                            <div key={index} className='flex flex-col gap-5 w-full'>
                                <h3 className='text-2xl font-bold'>{pregunta.title}</h3>
                                <div className='flex flex-col gap-14 w-full '>
                                    {pregunta?.preguntas.map((pregunta, index) => (
                                        <Collapse className='w-full' key={index} accordion>
                                            <Collapse.Panel header={pregunta.question} key={index}>
                                                <p>{pregunta.answer}</p>
                                            </Collapse.Panel>
                                        </Collapse>
                                    ))}
                                </div>
                            </div>
                        ))
                    }
                    {
                        servicio?.Preguntas && !servicio?.preguntasSeparadas &&
                        <div className='self-start flex flex-col gap-14 w-full '>
                            {servicio?.Preguntas.map((pregunta, index) => (
                                <Collapse
                                    onMouseEnter={selectEnter}
                                    onMouseLeave={selectExit}
                                    className='w-full bg-primary cursor-none ' key={index} accordion>
                                    <Collapse.Panel className='cursor-none'
                                        onMouseEnter={selectEnter}
                                        onMouseLeave={selectExit}
                                        header={pregunta.question} key={index}>
                                        <p>{pregunta.answer}</p>
                                    </Collapse.Panel>
                                </Collapse>
                            ))}
                        </div>}
                </div>
            </div>
        </BgColorContainer>
    )
}

export default PreguntasFrecuentes