import { Button } from 'antd'
import React, { useContext } from 'react'
import CursorContext from '../../../providers/Cursor'
import BgColorContainer from '../../../Layout/BgColorContainer'

function App({ servicio, app }) {

    const { blackSectionEnter, blackSectionExit } = useContext(CursorContext)

    return (
        <BgColorContainer color={servicio?.color == 'white' ? false : 'white'}>

            <div
                className={'w-full h-full px-5 md:px-0 md:pt-20 py-14 bg-primary text-secondary'}>
                <div className='container w-full'>
                    <div className='flex flex-col md:flex-row justify-between gap-10 mb-20 md:mb-0'>
                        <div className='md:w-1/2 flex flex-col gap-10'>
                            <h2 className='md:text-5xl text-2xl font-bold '>
                                {app?.title}
                            </h2>
                            <p className='text-2xl text-justify'>
                                {app?.description}
                            </p>
                            <ul className='flex flex-col gap-5'>
                                {
                                    app?.contenido?.map((feature, index) => (
                                        <li key={index} className='text-base md:text-2xl list-disc text-justify'>
                                            {feature}
                                        </li>
                                    ))
                                }
                            </ul>
                            {
                                app.demo && (
                                    <>
                                        <p className='text-lg'>
                                            Puedes Solicitar una demo de manera gratuita sin ningún compromiso.
                                        </p>

                                        <Button
                                            onMouseEnter={blackSectionEnter}
                                            onMouseLeave={blackSectionExit}
                                            className='self-center bg-secondary text-primary px-10 py-5 cursor-none'>
                                            Solicitar demo
                                        </Button>
                                    </>
                                )
                            }
                        </div>
                        <img src={'https://awna.s3.sa-east-1.amazonaws.com/iPhone-13-PRO-development.d37gdaapdrujs5.amplifyapp.com+(1).png'} alt="App" className='md:w-[300px]' />
                    </div>
                    <p className='font-bold text-2xl mb-5'>
                        Casos de Uso :
                    </p>
                    <ul className='flex flex-col gap-5'>
                        {
                            app?.casosDeUso?.map((feature, index) => (
                                <li key={index} className='text-lg  list-disc text-justify'>
                                    {feature}
                                </li>
                            ))
                        }
                    </ul>
                </div>

            </div >
        </BgColorContainer>
    )
}

export default App