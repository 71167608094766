import BgColorContainer from "../Layout/BgColorContainer";

function PageSeparator({
  videoSrc = "https://awna.s3.sa-east-1.amazonaws.com/videos/awna_metaball_anim_+3_+blur.mp4",
  additionalClasses = "",
}) {
  return (


    <div
      className={`
        relative 
        w-full 
        block
        ${additionalClasses}
        `}
    >
      <video
        className={` 
          inset-0 
          w-full 
          h-[30vh]
          md:h-[20vh]
          lg:h-[45vh]
          aspect-video
          object-cover 
          z-10 
          md:block
          `}
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={videoSrc} type="video/mp4" />
        {/* Fallback para navegadores que no soporten video */}
        <img
          src={videoSrc}
          alt="Fondo separador"
          className="
          w-full 
          h-full 
          object-cover 
          opacity-70 
          transition-opacity 
          duration-300 
          hover:opacity-100
          "
        />
      </video>
    </div>

  );
}

export default PageSeparator;